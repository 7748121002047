export const formatNum = (number) => {
  if (Number(number) === 0) {
    return 0;
  }
  if (Number(number) * 10 ** 5 < 1) {
    for (let i = 1; i <= 60; i++) {
      if (Number(number) * 10 ** i > 0.99999) {
        return (
          <div style={{ display: "flex" }}>
            <div className="c1">0.0</div>
            <div
              className="mobile-fmt"
              style={{ fontSize: "65%", marginTop: "5px" }}
            >
              {i - 1}
            </div>
            <div className="c3">{number.toString().slice(i + 1, i + 4)}</div>
          </div>
        );
      }
    }
  } else {
    if (Number(number) >= 1) {
      return `${new Intl.NumberFormat("en-US").format(
        Number(number).toFixed(7)
      )}`;
    } else {
      return `${Number(number).toFixed(7)}`;
    }
  }
};

export const decimalPrice = (number) => {
  if (number && Number(number) * 10 ** 5 < 1) {
    for (let i = 1; i <= 60; i++) {
      if (Number(number) * 10 ** i > 0.99999) {
        return i - 1;
      }
    }
  } else {
    return 5;
  }
};

export const formatNumNotDolla = (number) => {
  if (Number(number) * 10 ** 5 < 1) {
    for (let i = 1; i <= 60; i++) {
      if (Number(number) * 10 ** i > 0.99999) {
        return (
          <div style={{ display: "flex" }}>
            <div className="c1">$0.0</div>
            <div
              className="mobile-fmt"
              style={{ fontSize: "65%", marginTop: "5px" }}
            >
              {i - 1}
            </div>
            <div className="c3">{number.toString().slice(i + 1, i + 4)}</div>
          </div>
        );
      }
    }
  } else {
    if (Number(number) >= 1) {
      return `${new Intl.NumberFormat("en-IN").format(
        Number(number).toFixed(7)
      )}`;
    } else {
      return Number(number).toFixed(7);
    }
  }
};
